@keyframes slideup {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
  to {
    transform: translate3d(0, -30px, 0);
  }
}

.CopyBadge-popover {
  opacity: 0;
  animation: slideup 0.2s ease-in-out forwards,
    slide-exit 0.3s 1s linear forwards;
}

.CopyBadge-img svg {
  fill: #79808d;
}
